<template>
  <b-row class=""></b-row>
</template>

<script type="text/babel">
import { apiClient } from '@/util/resources';

export default {
  name: 'regActivate',

  components: {},
  computed: {},
  watch: {},
  data() {
    return {
      token: '',
    };
  },

  methods: {
    activateRegistration() {
      let this_ = this;
      apiClient
        .post(`api/users/activate/${this_.token}`, {})
        .then((response, error) => {
          this_._debug('regactivated', response);
          this.$root.$bvToast.toast(this_.$t('reg_success'), {
            title: this_.$t('success'),
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-top-center',
          });

          this.$router.push({ name: 'login' });
        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
          this.submitRegistrationSent = false;
          this.$router.push({ name: 'login' });
        });
    },
  },

  created() {
    this.token = this.$route.params.token;
    this.activateRegistration();
  },

  mounted() {},

  beforeDestroyed() {},
};
</script>

<style lang="scss" rel="stylesheet/scss">
//@import "@/styles/colors.scss";
</style>
